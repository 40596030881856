type ConfigParams = {
    dsn: string;
    buildVersion: string;
    environment: string;
    enabled: boolean;
}

export const getDefaultSentryConfig = (config: ConfigParams) => {
    return {
        enabled: config.enabled,
        dsn: config.dsn,
        environment: config.environment,
        release: config.buildVersion,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 0.005,
        tracesSampleRate: 0.001,

        beforeSend: (ev: any, hint: any) => {
            const originalException = hint.originalException as any;

            // Ignore 401 errors
            if (originalException?.response?.status === 401) {
                return null;
            }

            return ev;
        },
        ignoreErrors: [
            "Not implemented on this platform", // Caused by LastPass safari extension
            "Network Error", // Random Safari network errors that are not actionable or interesting
            "cannot call methods on dialog2 prior to initialization; attempted to call method 'destroy'", // Innocent error that happens occasionally, not actionable or worth fixing
            /timeout of \d+ms exceeded/, // Connection timeouts are not actionable or interesting
            "The object can not be cloned.", // Useless safari error
            "Permission denied to access property \"nodeType\"", // CKEditor4 error, browser quirk, has no side effects and not actionable
            "ResizeObserver loop limit exceeded", // A page observer didn't notify all listeners within 1 animation frame which is not actionable or interesting
            /Failed to complete negotiation with the server.*/,
            "Request failed with status code 500", // 500 errors will be logged by the server, the client has no useful info
            "Request failed with status code 403", // 403 errors occur when user's session has timed out
            "The underlying connection was closed before the hub handshake could complete.", // SignalR connection errors aren't actionable
            "Error parsing handshake response: Error: Expected a handshake response from the server.", // SignalR connection errors aren't actionable
        ],
        denyUrls: [
            // Deny errors caused by extensions
            /extensions\//i,
            /^chrome:\/\//i,
            /^chrome-extension:\/\//i,
            /safari-(web-)?extension:/
        ]
    }
}