import * as Sentry from "@sentry/react";
import { getDefaultSentryConfig } from "shared/sentry/initConfig"

Sentry.init({
    integrations: [
        new Sentry.Replay({
            maskAllInputs: false,
            maskAllText: false,
        })
    ],

    ...getDefaultSentryConfig({
        dsn: window.site.sentryDsn,
        buildVersion: window.site.build,
        environment: window.site.sentryEnvironment,
        enabled: process.env.NODE_ENV === "production"
    })
});
